<template>
    <div class="my-3">
        <a :href="`${linkBoleto}`" target="_blank"
            ><BIconFileEarmarkArrowDownFill
              class="text-2xl sm:text-4xl inline-block text-main-pink-500"
            />
            <span
              class="text-lg sm:text-2xl ml-2 text-main-purple-500 inine-block align-bottom"
            >
              <strong>Vencimento:</strong> {{ dataVencimento }} -
              <strong> R${{ this.returnValue }}</strong></span
            ></a
          >
    </div>
</template>

<script>

export default {
  name: "SegundaViaBox",
  data() {
    return {
    returnValue: this.getPlanValue(),
    };
  },
  props: {
    linkBoleto: String,
    dataVencimento: String,
    valorBoleto: Number,
  },
  methods: {
    getPlanValue() {
    //   let preco = this.precoPlano;
    //   let digits = preco?.toString().split(".");
    //   return digits;
    let preco = this.valorBoleto;
    let digits = preco?.toString().split(".");
    if(!digits[1]) {
        console.log(digits[0] + ',00');
        return digits[0] + ',00';
    }
    if(digits[1]) {
        if(digits[1].length == 1) {
            console.log(digits[0] + ',' + digits[1] + '0');
            return digits[0] + ',' + digits[1] + '0';
        }
        console.log(digits[0] + ',' + digits[1]);
        return digits[0] + ',' + digits[1];
    }

    },

    getPromotionalValue() {
    //   let promoPlano = this.precoPromocional;
    //   if (promoPlano == 0) { promoPlano == 0.00 }
    //   let digitsPromo = promoPlano?.toString().split(".");
    //   return digitsPromo;
    },
  },
  created() {
    this.getPlanValue();
  },
};
</script>