<template>
    <!-- <div class="grid grid-rows-5 md:grid-rows-1 gap-6 md:gap-6 md:grid-cols-3 sm:items-stretch w-full"> -->
      <!-- <div class="w-full p-4 bg-white mb-8 border-0 border-x-8 border-main-yellow-500 text-main-pink-500 font-regular text-2xl"> -->
<!-- 
      <div class="w-full p-4 bg-gray-100 mb-12 border-x-8 border-0 border-main-pink-500 text-main-pink-500 md:text-center font-regular text-2xl md:text-3xl">
        <h2>Ofertas exclusivas da cidade de <strong>{{ getCityName() }}</strong></h2>
      </div> -->
    <div class="grid grid-rows-5 md:grid-rows-1 grid-flow-col gap-6 md:gap-3 md:grid-cols-5 sm:items-stretch w-full">
          <!--box plano--> 
          <box-plan
            v-for="(l, key) in this.response.data"
            :key="key"
            :titulo-plano="l.title"
            :subtitulo-plano="l.subtitle"
            :velocidade-plano="l.speed"
            :velocidade-promocional="l.promotional_speed"
            :preco-plano="l.price"
            :preco-promocional="l.promotional_price"
            :sva-plano="l.sva"
            :id-plano="l.id"
            :tipo-plano="this.typeId"
            :extras-plano="l.extras"
            :cidade-plano="this.cityId"
          />
          <!--fim box plano-->

    </div>
    <div class="w-full p-4 text-gray-500 mt-4 font-regular text-xs italic">
        <p>{{ returnOfferMessage()[0] }}</p>
        <p>{{ returnOfferMessage()[1] }}</p>
        <p>{{ returnOfferMessage()[2] }}</p>
    </div>

</template>
<script>
import BoxPlan from "@/components/layout/plans/BoxPlan.vue"; 
import LoadHome from "@/mixins/LoadHome";

export default {
    components: {
        BoxPlan,
    },
    data: () => ({
        urlBase: null,
        cityId: null,
        typeId: null,
    }),
  mixins: [LoadHome],
  methods: {
    getUrlBase() {
        this.cityId = this.idCidade;
        this.typeId = this.idTipo;
        this.urlBase = "plan/";
    },
    returnOfferMessage() {

      if(this.idCidade > 5 && this.idTipo != 2) {
        let returnDetails = ["*Oferta da velocidade de 450 Mega válida por 6 meses. Após este período o valor passa a ser R$ 99,90/mês","*Oferta da velocidade de 680 Mega/Watch válida por 6 meses. Após este período o valor passa a ser R$ 117,00/mês.", "*Oferta válida somente para esta localidade."];

        return returnDetails;
      } else {
        return "";
      }

    },
    // getCityName() {
    //   let varCity = parseInt(this.cityId)

    //   switch (varCity) {
    //     case 1:
    //       return "Belo Horizonte";
    //       break;
    //     case 2:
    //       return "Esmeraldas";
    //       break;
    //     case 3:
    //       return "Ribeirão das Neves";
    //       break;
    //     case 4:
    //       return "Sabará";
    //       break;
    //     case 5:
    //       return "Santa Luzia";
    //       break;
    //     case 6:
    //       return "Vespasiano";
    //       break;
    //     case 7:
    //       return "Justinópolis";
    //       break;
        
    //   }
    // }
  },
  props: {
    idCidade: Number,
    idTipo: Number,
  },
  created() {
    this.getUrlBase()
  }

}
</script>