<template>
  <div class="about-us">
    <main
      class="relative flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-4xl mx-auto mb-4">
          <h1 class="font-black text-main-purple-500 text-6xl text-center my-4">
            Segunda via
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
            Retire de maneira rápida e prática a segunda via de sua conta!
          </h3>
        </div>
        <div>
          <return-box
            v-if="this.status == 'error'"
            return-status="error"
            return-title="Ops! Algo deu errado..."
            >{{ this.response }}</return-box
          >
        </div>
        <div class="w-11/12 sm:w-full max-w-4xl mx-auto flex justify-center">
          <form @submit.prevent="this.returnAddress()">
            <input
              v-maska
              data-maska="['###.###.###-##', '##.###.###/####-##']"
              class="w-full sm:w-1/2 form-input inline-block font-regular bg-gray-100 main-purple-500 text-3xl tracking-tight px-8 py-6 rounded-3xl my-4 sm:mr-4"
              placeholder="Digite seu CPF ou CNPJ"
              id="cpf"
              name="cpf"
              required
              v-model="clientDocument"
            />
            <button
              class="inline-block font-regular bg-main-purple-500 text-main-yellow-500 text-3xl uppercase tracking-tight px-8 py-6 rounded-3xl my-4 mx-auto sm:mx-0 w-full sm:w-5/12"
            >
              Emitir <strong class="font-black">Boleto</strong>
            </button>
          </form>
        </div>

        <div
          class="w-full max-w-3xl mx-auto my-6 py-2 sm:p-4 border-y-4 border-dashed"
          v-if="this.status == 'success'"
        >
        <div v-for="(l, key) in this.response"
        :key="key">
          <segunda-via-box
            :dataVencimento="l.data_vencimento"
            :linkBoleto="l.link"
            :valorBoleto="l.valor"
          />
        </div>

        </div>

        <h3 class="font-regular text-main-pink-500 text-xl text-center mt-4">
          Por favor insira o seu <strong>CPF ou CNPJ</strong> e pressione o
          botão <strong>Emitir boleto</strong>.
        </h3>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import ReturnBox from "@/components/layout/goods/ReturnBox.vue";
import SegundaViaBox from "@/components/layout/goods/SegundaViaBox.vue"; 

export default {
  name: "Segunda Via",
  components: {
    ReturnBox,
    SegundaViaBox,
  },
  data: () => ({
    clientDocument: null,
    errors: [],
    response: [],
    fullPage: false,
    status: null,
  }),
  methods: {
    formatString(string) {
      return string.toString().replace(/[^a-z0-9]/gi, "");
    },

    returnAddress() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        backgroundColor: "#FFC000",
        color: "#581E9B",
        opacity: 0.9,
      });
      let clientDocument = this.formatString(this.clientDocument);

      axios
        .get(this.apiUrl + "/DuplicateBankSlip/" + clientDocument)
        .then((response) => {
          this.response = response.data;
          this.status = "success";
        })
        .catch((errors) => {
          this.response = errors.response.data;
          this.status = "error";
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
